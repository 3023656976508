import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SEO from '../components/seo';
import { isGlobalSite } from '../helpers/data';
import FAQLayout from '../layouts/FAQLayout';

const FAQPage = () => {
  if (isGlobalSite) {
    return <SEO meta={[{ 'http-equiv': 'refresh', content: '0;url=/' }]} />;
  }
  const { faqPage } = useStaticQuery(
    graphql`
      query faqPage {
        faqPage: page(slug: { eq: "/frequently-asked-questions/" }) {
          slug
          title
          foreignId
          className
          metaDescription
          metaTitleFormatted
          metaTags
          ogImage
          content
        }
      }
    `,
  );

  const parsedFaqContent = JSON.parse(`${faqPage.content}`);
  const faqs = parsedFaqContent.map((qa) => ({
    question: qa.Question,
    answer: qa.Answer,
  }));
  return <FAQLayout pageData={faqPage} faqs={faqs} />;
};

FAQPage.propTypes = {};
FAQPage.defaultProps = {};

export default FAQPage;
